import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseComponent } from '../base';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ValueFieldModule } from '../../modules/value-field/value-field.module';
import { ApiResponseError, EUserStatus, IAdminProfile, IFormGroupSchema } from '@lib/interfaces';
import { AngularMaterialModule } from 'src/app/material.module';
import { AccessManagementService } from '@lib/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StatusComponent } from '../status/status.component';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
    selector: 'app-admin-profile-modal',
    standalone: true,
    templateUrl: './admin-profile-modal.component.html',
    styleUrls: ['./admin-profile-modal.component.scss'],
    imports: [AngularMaterialModule, ValueFieldModule, StatusComponent, NgxMatIntlTelInputComponent, ReactiveFormsModule, FormsModule, SpinnerComponent],
})
export class AdminProfileModalComponent extends BaseComponent {
    public adminProfileForm: FormGroup<IFormGroupSchema<IAdminProfile>>;
    public isEditBtnDisabled = false;
    protected isEditMode = false;
    protected isLoading = true;
    protected userStatus: typeof EUserStatus = EUserStatus;
    protected userId = localStorage.getItem('id');

    constructor(
        private readonly formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<AdminProfileModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { message: string },
        public accessManagementService: AccessManagementService,
        public readonly snackbar: MatSnackBar,
    ) {
        super();

        this.initForm();
        this.fetchAdminProfileDetails();
        this.adminProfileForm.disable();
    }

    initForm(): void {
        this.adminProfileForm = this.formBuilder.group<IFormGroupSchema<IAdminProfile>>({
            first_name: this.formBuilder.nonNullable.control<string>('', [Validators.required]),
            last_name: this.formBuilder.nonNullable.control<string>('', [Validators.required]),
            email: this.formBuilder.nonNullable.control<string>('', [Validators.required, Validators.pattern(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)]),
            mobile: this.formBuilder.nonNullable.control<string>(''),
            status: this.formBuilder.nonNullable.control<EUserStatus>(this.userStatus.ACTIVE),
            two_fa_required: this.formBuilder.nonNullable.control<boolean>(false),
        });
    }

    fetchAdminProfileDetails(): void {
        if (!this.userId) return;

        this.addSubscription(
            this.accessManagementService.fetchUserById(this.userId).subscribe({
                next: (adminData: IAdminProfile) => {
                    this.adminProfileForm.patchValue(adminData);
                    this.isLoading = false;
                },
                error: (error: ApiResponseError) => {
                    if ([401, 403].includes(error.status)) this.dialogRef.close();
                    this.createApiErrorSnackBar(this.snackbar, error);
                },
            }),
        );
    }

    updateAdminProfileDetails(): void {
        if (this.userId) {
            this.addSubscription(
                this.accessManagementService.updateUser(this.userId, this.adminProfileForm.getRawValue()).subscribe({
                    next: () => {
                        this.adminProfileForm.disable();
                        this.isEditMode = false;
                        this.isEditBtnDisabled = false;
                        this.createApiSuccessSnackBar(this.snackbar, 'User updated successfully.');
                        this.dialogRef.close();
                    },
                    error: (error: ApiResponseError) => {
                        if ([401, 403].includes(error.status)) this.dialogRef.close();
                        this.createApiErrorSnackBar(this.snackbar, error);
                    },
                }),
            );
        }
    }

    enableFields(): void {
        this.isEditBtnDisabled = true;
        this.isEditMode = true;
        this.adminProfileForm.enable();
    }

    changeStatus(value: EUserStatus): void {
        this.adminProfileForm.get('status')?.setValue(value);
        this.adminProfileForm.get('status')?.markAsDirty();
    }

    isInvalid(control: AbstractControl): boolean {
        return (control.touched || control.dirty) && control.invalid;
    }
}
