import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../../../material.module';
import { SettingsService } from '@lib/services/settings/settings.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordModalComponent } from '../change-password-modal/change-password-modal.component';
import { AdminProfileModalComponent } from '../admin-profile-modal/admin-profile-modal.component';

@Component({
    standalone: true,
    imports: [CommonModule, AngularMaterialModule],
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    @Input() drawer: any;

    @Output()
    open: EventEmitter<boolean> = new EventEmitter();

    isOpen = false;
    primary_color = '';
    constructor(private router: Router, public settingService: SettingsService, private dialog: MatDialog) {}

    ngOnInit() {
        this.settingService.settingsDetails.subscribe(({ primary_color = '' }) => {
            this.primary_color = primary_color;
        });
    }

    isRouteMatch(route: string): boolean {
        if (this.router.url === route) return true;

        return false;
    }

    toggleDropdown(): void {
        this.isOpen = !this.isOpen;
    }

    logout(): void {
        const tenantKey = localStorage.getItem('tenantKey') || ''
        localStorage.removeItem('accessToken');
        localStorage.removeItem('customToken');
        localStorage.removeItem('permissions');
        localStorage.removeItem('selectedUser');
        localStorage.removeItem('tenantKey');
        this.router.navigate([`/auth/${tenantKey}/login`]);
    }

    openNav() {
        this.open.emit(true);
    }

    openChangePassword(): void {
        this.dialog.open(ChangePasswordModalComponent, {
            width: '588px',
        });
    }

    viewAdminProfile(): void {
        this.dialog.open(AdminProfileModalComponent, {
            autoFocus: false,
            width: '660px',
        });
    }
}
