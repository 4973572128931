import { AfterContentInit, Component, ContentChildren, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import { Nullable, Optional } from '@lib/interfaces';
import { IValueFieldOption, ValueFieldOptionDirective } from '@lib/modules/value-field/directives';
import { BaseValueFieldComponent, FormControlInput, SimpleControlFieldComponent } from '@lib/modules/value-field/base';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { debounceTime, fromEvent, Subscription } from 'rxjs';

@Component({
    selector: 'app-value-field-select',
    templateUrl: './value-field-select.component.html',
    styleUrls: ['./value-field-select.component.scss'],
    providers: [{ provide: BaseValueFieldComponent, useExisting: ValueFieldSelectComponent }],
})
export class ValueFieldSelectComponent<T> extends SimpleControlFieldComponent<T> implements OnInit, AfterContentInit {
    @Input() public placeholder = '';
    @Output() public scrollBottom: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('selectBox') protected selectBox: Optional<ElementRef<HTMLDivElement>>;
    @ViewChild('selectBoxTrigger') protected selectBoxTrigger: Optional<MatMenuTrigger>;
    @ViewChild('selectOptionMenu') protected selectOptionsMenu: Optional<MatMenu>;
    @ContentChildren(ValueFieldOptionDirective<T>) protected valueFieldOptionList: Optional<QueryList<ValueFieldOptionDirective<T>>>;

    protected inputOptions: Array<IValueFieldOption<T>> = [];
    protected selectedOption: Nullable<IValueFieldOption<T>> = null;
    protected scrollEventSubscription: Nullable<Subscription> = null;

    private _optionsLoading = false;

    public get optionsLoading(): boolean {
        return this._optionsLoading;
    }

    @Input()
    public set optionsLoading(optionsLoading: boolean) {
        this._optionsLoading = optionsLoading;

        if (optionsLoading) this.scrollOptionsMenuToBottom();
    }

    protected get placeholderText(): string {
        if (this.placeholder) return this.placeholder;

        return this.controlDisplayName;
    }

    public ngOnInit(): void {
        this.subscribeToControlChanges();
    }

    public ngAfterContentInit(): void {
        this.subscribeToSelectOptionChanges();
    }

    protected performPostOptionMenuOpeningTasks(): void {
        this.control.markAsTouched();

        const optionMenu: Nullable<HTMLElement> = document.querySelector('.select-option');
        if (!optionMenu || !this.selectBox) return;

        optionMenu.style.minWidth = `${this.selectBox.nativeElement.clientWidth}px`;

        this.subscribeToOptionsMenuScrollEvent();
    }

    protected setSelectedOption(inputOption: IValueFieldOption<T>): void {
        if (this.control.value === inputOption.value) {
            return;
        }

        this.control.setValue(inputOption.value);

        if (this.selectBoxTrigger) this.selectBoxTrigger.closeMenu();

        this.control.markAsDirty();
    }

    protected cleanUpMenuScrollSubscription(): void {
        if (!this.scrollEventSubscription) return;

        if (!this.scrollEventSubscription.closed) this.scrollEventSubscription.unsubscribe();

        this.scrollEventSubscription = null;
    }

    private subscribeToControlChanges(): void {
        this.subscribeToControlValueChanges(this.control);

        this.addSubscription(
            this.controlChanges.subscribe((control: FormControlInput<T>): void => {
                this.subscribeToControlValueChanges(control);
            }),
        );
    }

    private subscribeToControlValueChanges(control: FormControlInput<T>): void {
        this.setSelectedValue();

        if (this.controlValueChangeSubscription && !this.controlValueChangeSubscription.closed) this.controlValueChangeSubscription.unsubscribe();

        this.controlValueChangeSubscription = control.valueChanges.subscribe((): void => {
            this.setSelectedValue();
        });
    }

    private subscribeToSelectOptionChanges(): void {
        if (!this.valueFieldOptionList) return;

        this.createInputOptions(this.valueFieldOptionList);

        this.addSubscription(
            this.valueFieldOptionList.changes.subscribe((valueFieldOptionList: QueryList<ValueFieldOptionDirective<T>>): void => {
                this.createInputOptions(valueFieldOptionList);
            }),
        );
    }

    private subscribeToOptionsMenuScrollEvent(): void {
        if (!this.selectOptionsMenu) return;

        const optionsMenuElement: Nullable<Element> = document.querySelector(`#${this.selectOptionsMenu.panelId}`);

        if (!optionsMenuElement) return;

        this.scrollEventSubscription = fromEvent(optionsMenuElement, 'scroll')
            .pipe(debounceTime(250))
            .subscribe((event: Event): void => {
                const { scrollHeight, clientHeight, scrollTop }: HTMLDivElement = event.target as HTMLDivElement;

                const hasReachedBottom: boolean = Math.floor(scrollHeight - clientHeight - scrollTop) === 0;

                if (hasReachedBottom) this.scrollBottom.emit();
            });
    }

    private scrollOptionsMenuToBottom(): void {
        if (!this.selectOptionsMenu) return;

        const optionsMenuElement: Nullable<Element> = document.querySelector(`#${this.selectOptionsMenu.panelId}`);

        if (!optionsMenuElement) return;

        optionsMenuElement.scrollTop = optionsMenuElement.scrollHeight;
    }

    private createInputOptions(valueFieldOptionList: QueryList<ValueFieldOptionDirective<T>>): void {
        this.inputOptions = valueFieldOptionList.map(({ appValueFieldOption, templateRef }: ValueFieldOptionDirective<T>): IValueFieldOption<T> => {
            return { value: appValueFieldOption, templateRef };
        });

        this.setSelectedValue();
    }

    private setSelectedValue(): void {
        const selectedOption: Optional<IValueFieldOption<T>> = this.inputOptions.find((inputOption: IValueFieldOption<T>): boolean => inputOption.value === this.control.value);

        this.selectedOption = selectedOption ?? null;
    }
}
