import { AuthExpiredByType, CardEntry, ConfirmationType, EcommerceSecurityLevel, FilterMatcher, FilterOperator, LoadTransactionType, MessageType, PosEnvironment, TransactionExtendedType, TransferSourceChannel } from '@lib/enums';

export interface ITransactionFilter {
    field: string;
    operator: FilterOperator;
    values: Array<string>;
}

export interface ITransaction {
    amount: string;
    count: string;
}

export interface ITransactionStatus {
    approved: ITransaction;
    declined: ITransaction;
    settled: ITransaction;
    value: string;
}

export interface IFeeDetail {
    fee_id: string;
    description: string;
    amount: number;
    currency: string;
}

export interface IFee {
    active: boolean;
    amount: number;
    created: Date;
    currency: string;
    description: string;
    id: string;
    modified: Date;
    percentage?: number;
    range: any[];
    source_channel: string;
    transaction_origin: string;
    transaction_type: string;
    transfer_type: any[];
    type: string;
}

export interface ISweepDetail {
    debit_from_currency: string;
    debit_from_amount: number;
    credit_to_currency: string;
    credit_to_amount: number;
    exchange_rate: number;
}

export interface ITransactionAdvanceFilter {
    card_id: string;
    user_id: string;
    account_id1: string;
    card_product_1: string;
    billing_currency_account: string;
    parent_transaction_id: string;
    status_code: string;
    transaction_type: TransactionExtendedType;
    transfer_id: string;
    transmission_date_time: string;
    auth_expired_by: AuthExpiredByType;
    network_transaction_id: string;
    auth_expired_at: string;
    rrn: string;
    sender_user_id: string;
    transaction_amount: number;
    transaction_currency: string;
    mcc: string;
    billing_amount: number;
    merchant_name: string;
    billing_currency: string;
}

export interface ITransactionIndex {
    id: string;
    transaction_timestamp: string;
    parent_transaction_id: string;
    network: string;
    message_type: MessageType;
    transaction_type: TransactionExtendedType;
    transaction_description: string;
    transmission_date_time: string;
    date_time_acquirer: string;
    card_id: string;
    card_first6_digits: string;
    card_last4_digits: string;
    card_expiry_date: string;
    user_id: string;
    account_id1: string;
    card_product_1: string;
    account_id2: string;
    card_product_2: string;
    acquirer_id: string;
    merchant_id: string;
    mcc: string;
    merchant_name: string;
    merchant_city: string;
    merchant_country: string;
    terminal_id: string;
    stan: string;
    rrn: string;
    auth_id_response: string;
    network_transaction_id: string;
    transaction_amount: number;
    transaction_currency: string;
    billing_amount: number;
    billing_currency: string;
    billing_amount_account: number;
    billing_currency_account: string;
    conversion_rate_billing_account: number;
    acquirer_fee_amount: number;
    conversion_rate_billing: string;
    fee_amount: number;
    fee_details: Array<IFeeDetail>;
    original_amount: number;
    original_amount_billing: string;
    status_code: string;
    status_description: string;
    transfer_id: string;
    load_id: string;
    unload_id: string;
    eci: string;
    ecommerce_security_level: EcommerceSecurityLevel;
    card_entry: CardEntry;
    pos_environment: PosEnvironment;
    fallback: boolean;
    pin_present: boolean;
    moto: boolean;
    recurring: boolean;
    installment_transaction: boolean;
    auth_expired_at: string;
    auth_expired_by: AuthExpiredByType;
    is_reversed: ConfirmationType;
    is_settled: ConfirmationType;
    source_channel: TransferSourceChannel;
    notes: string;
    sender_user_id: string;
    external_reference_number: string;
    external_reference_date: string;
    reference_number: string;
    sweep_details: Array<ISweepDetail>;
    type: LoadTransactionType;
}

export interface ICursor {
    after: string;
}

export interface ISearchTransactionRequest {
    query: string;
    sort_by: string;
    order_by: string;
    matcher: FilterMatcher;
    filters: Array<ITransactionFilter>;
    cursors: ICursor;
    limit: number;
}

export interface IPaging {
    cursors: ICursor;
}

export interface ISearchTransactionResponse {
    paging?: IPaging;
    data: Array<ITransactionIndex>;
}

export interface ILinkFeeForm {
    fee: string;
}

export const FeeTypes = [
    { key: 'FLAT', value: 'Flat' },
    { key: 'PERCENTAGE', value: 'Percentage' },
];

export const TransactionTypes = [
    { key: 'PURCHASE', value: 'Purchase' },
    { key: 'CASH_WITHDRAWAL', value: 'Cash Withdrawal' },
    { key: 'REFUND', value: 'Refund' },
    { key: 'BALANCE_INQUIRY', value: 'Balance Inquiry' },
    { key: 'ACCOUNT_VERIFICATION', value: 'Account Verification' },
    { key: 'WALLET_DEBIT', value: 'Wallet Debit' },
    { key: 'WALLET_CREDIT', value: 'Wallet Credit' },
    { key: 'WALLET_TRANSFER', value: 'Wallet Transfer' },
];

export const TransactionOrigins = [
    { key: 'DOMESTIC', value: 'Domestic' },
    { key: 'FOREIGN', value: 'Foreign' },
    { key: 'ANY', value: 'Any' },
];

export const SourceChannels = [
    { key: 'ANY', value: 'Any' },
    { key: 'MPGS', value: 'MPGS' },
    { key: 'DAPI', value: 'DAPI' },
];
