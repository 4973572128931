import { SortDirection } from '@lib/modules/table/components';

export enum Type {
    FLAT = 'FLAT',
    PERCENTAGE = 'PERCENTAGE',
    MAX_PERC_OR_FLAT = 'MAX_PERC_OR_FLAT',
    MIN_PERC_OR_FLAT = 'MIN_PERC_OR_FLAT',
}

export enum ChargeType {
    PHYSICAL_CARD_ISSUANCE = 'PHYSICAL_CARD_ISSUANCE',
    VIRTUAL_CARD_ISSUANCE = 'VIRTUAL_CARD_ISSUANCE',
    PHYSICAL_CARD_REPLACEMENT = 'PHYSICAL_CARD_REPLACEMENT',
    VIRTUAL_CARD_REPLACEMENT = 'VIRTUAL_CARD_REPLACEMENT',
    MAINTENANCE = 'MAINTENANCE',
    CHANGE_PIN = 'CHANGE_PIN',
    LATE_PAYMENT = 'LATE_PAYMENT',
    DELIVERY = 'DELIVERY',
}

export enum ChargeApplicationType {
    INSTANT = 'INSTANT',
    SCHEDULED = 'SCHEDULED',
}

export enum ChargeAccountType {
    PREPAID = 'PREPAID',
    MASTER = 'MASTER',
}

export enum ChargeFrequency {
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
}

export interface ICharge {
    id: string;
    description: string;
    type: Type;
    currency: string;
    amount: number;
    percentage: number;
    active: boolean;
    charge_type: ChargeType;
    frequency: ChargeFrequency;
    application_type: ChargeApplicationType;
    account_type: ChargeAccountType;
    created: string;
    modified: string;
}

export enum LinkOrUnlink {
    Link = 'link',
    Unlink = 'unlink',
}

export interface ILinkUnlinkChargeRequest {
    ids: Array<string>;
}

export interface IChargeQueryParams {
    charge_type: ChargeType;
    ids: string;
    limit: number;
    after: string;
    sort_by: string;
    order_by: SortDirection;
}

export type IMutateCharge = Omit<ICharge, 'created' | 'modified'>;
