import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from '../base';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AngularMaterialModule } from 'src/app/material.module';

@Component({
    selector: 'app-action-success-modal',
    standalone: true,
    imports: [CommonModule, AngularMaterialModule],
    templateUrl: './action-success-modal.component.html',
    styleUrls: ['./action-success-modal.component.scss'],
})
export class ActionSuccessModalComponent {
    constructor(public dialogRef: MatDialogRef<ActionSuccessModalComponent>, @Inject(MAT_DIALOG_DATA) public data: { message: string }) {}
}
