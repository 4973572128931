// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  api_url: 'https://api.platform.ae-1.nymcard.com/',
  version: 'beta',
  apiKey: '',
  xConsumerUsername: '',
};

export const tenantSetting = [
  {
      key: 'tenant32',
      name: 'nublo',
  },
  {
      key: 'tenant30',
      name: 'spotii',
  },
  {
      key: 'tenant29',
      name: 'wally',
  },
  {
      key: 'tenant28',
      name: 'mamo',
  },
  {
      key: 'tenant27',
      name: 'paysky',
  },
  {
      key: 'tenant26',
      name: 'easytip',
  },
  {
      key: 'tenant25',
      name: 'yuze',
  },
  {
      key: 'tenant24',
      name: 'npay',
  },
  {
      key: 'tenant23',
      name: 'myffin',
  },
  {
      key: 'tenant22',
      name: 'zywa',
  },
  {
      key: 'tenant21',
      name: 'f5',
  },
  {
      key: 'tenant20',
      name: 'finwell',
  },
  {
      key: 'tenant19',
      name: 'samsung',
  },
  {
      key: 'tenant18',
      name: 'crunch',
  },
  {
      key: 'tenant17',
      name: 'plutocard',
  },
  {
      key: 'tenant16',
      name: 'savii',
  },
  {
      key: 'tenant15',
      name: 'savmoney',
  },
  {
      key: 'tenant14',
      name: 'blink',
  },
  {
      key: 'tenant13',
      name: 'pemo',
  },
  {
      key: 'tenant12',
      name: 'alaanpay',
  },
  {
      key: 'tenant0',
      name: 'nymcard-internal',
  },
  {
      key: 'tenant10',
      name: 'bankiom',
  },
  {
      key: 'tenant9',
      name: 'nxxopay',
  },
  {
      key: 'tenant7',
      name: 'xpence',
  },
  {
      key: 'tenant6',
      name: 'qashi',
  },
  {
      key: 'tenant5',
      name: 'zyyp',
  },
  {
      key: 'tenant4',
      name: 'qashio',
  },
  {
      key: 'tenant3',
      name: 'edfundo',
  },
  {
      key: 'tenant01',
      name: 'nasswallet',
  },
];


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
