import { inject } from '@angular/core';
import { CanMatchFn, Route, Router, UrlSegment } from '@angular/router';

type AuthGuardOptions = {
    requiresAuthentication: boolean;
};

const defaultAuthGuardOptions = (): AuthGuardOptions => ({
    requiresAuthentication: true,
});

export const authGuard = (options: AuthGuardOptions = defaultAuthGuardOptions()): CanMatchFn => {
    return (_: Route) => {
        const router = inject(Router);
        if (options.requiresAuthentication === !!localStorage.getItem('accessToken')) {
            return true;
        }

        return options.requiresAuthentication
            ? router.createUrlTree(['/auth/tenant0_api/login'])
            : router.createUrlTree(['/']);
    };
};
