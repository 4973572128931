import { HttpInterceptorFn } from '@angular/common/http';
import { tap } from 'rxjs';
import { inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

export const authInterceptor: HttpInterceptorFn = (request, next) => {
    const router = inject(Router);
    const activatedRoute = inject(ActivatedRoute);
    const accessToken = localStorage.getItem('accessToken') || '';
    const customToken = localStorage.getItem('customToken') || '';
    const xConsumerUsername = activatedRoute.snapshot.firstChild?.paramMap.get('tenant') || ''

    if (!accessToken) {
        request = request.clone({ headers: request.headers.set('X-Consumer-Username', xConsumerUsername) });
        if (!request.headers.get('x-no-authorization')) {
            request = request.clone({ headers: request.headers.set('Authorization', `${customToken}`) });
        }
    }
    if (accessToken && !request.headers.get('x-no-authorization')) {
        request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${accessToken}`) });
    }

    if (request.headers.has('x-no-authorization')) {
        request = request.clone({ headers: request.headers.delete('x-no-authorization') });
    }

    return next(request).pipe(
        tap({
            error: ({ status, ...err }) => {
                const tenantKey = localStorage.getItem('tenantKey') || ''
                if (status === 401) {
                    router.navigateByUrl(`auth/${tenantKey}/login`);
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('selectedUser');
                    localStorage.removeItem('customToken');
                    localStorage.removeItem('tenantKey');
                } else if (status === 403 && err.error.detail === 'Current session is expired, please login again.') {
                    router.navigateByUrl(`auth/${tenantKey}/login`);
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('selectedUser');
                    localStorage.removeItem('customToken');
                    localStorage.removeItem('tenantKey');
                }
            },
        }),
    );
};
