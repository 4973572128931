import { GraphType } from '../enums/home.enums';

export interface TransactionCountResponse {
    details: {
        data: Array<{
            value: string;
            approved: Record<GraphType, number>;
            declined: Record<GraphType, number>;
            settled: Record<GraphType, number>;
        }>;
    };
    average_transaction: string;
    transaction_count: string;
    settled_authorization: string;
    currency: string;
}
