import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { IRole } from '@lib/interfaces/role.interface';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    environment_url: string;
    settingsDetails: EventEmitter<any> = new EventEmitter();
    constructor(private http: HttpClient) {
        this.environment_url = environment.api_url;
    }

    public setTenantSetting(payload: any): Observable<any> {
        return this.http.post<any>(`${this.environment_url}nfront/v1/proxy/tenantsettings/portalconfiguration`, payload);
    }

    public updateTenantSetting(id: string, payload: any): Observable<any> {
        return this.http.put<any>(`${this.environment_url}nfront/v1/proxy/tenantsettings/portalconfiguration/${id}`, payload);
    }

    public getTenantSetting(): Observable<any> {
        return this.http.get<any>(`${this.environment_url}nfront/v1/proxy/tenantsettings/portalconfiguration/metadata`);
    }
}
