import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';

export const permissionGuard: CanActivateFn = (permissions: ActivatedRouteSnapshot) => {
    const tenantKey = localStorage.getItem('tenantKey')
    const userId = localStorage.getItem('id')
   if(tenantKey === 'tenant0_api') {
      let visibleTabs
      if(userId === 'administrator') {
        visibleTabs = ['b:customer', 'b:callcenter', 'b:customer:cards', 'b:accessmgmt']
      } else {
        visibleTabs = ['b:customer', 'b:customer:cards', 'b:callcenter']
      }

      if (permissions?.data['permission'].length === 0) return false;
      return visibleTabs.some(item => permissions?.data['permission'].some((permission: any) => permission.startsWith(item)));
    }

    if (permissions?.data['permission'].length === 0) return true;

    const allPermissions = localStorage.getItem('permissions')?.split(',');
    const hasPermission = allPermissions?.find((permission) => permissions?.data['permission'].includes(permission));

    return !!hasPermission;
};
