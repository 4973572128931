<ng-template #fieldEditor>
    <div class="d-flex flex-column justify-content-center">
        <input
            (focus)="control.markAsTouched()"
            (input)="setControlValue($event)"
            [attr.max]="inputType === 'number' ? max : null"
            [attr.maxlength]="inputType !== 'number' ? maxLength : null"
            [attr.min]="inputType === 'number' ? min : null"
            [attr.minlength]="inputType !== 'number' ? minLength : null"
            [attr.placeholder]="placeholderText"
            [attr.step]="inputType === 'number' ? step : null"
            [attr.type]="inputType"
            [class.disabled]="control.disabled"
            [class.input-invalid]="isInvalid(control)"
            [disabled]="control.disabled"
            [id]="createMinifiedName(controlDisplayName)"
            [name]="createMinifiedName(controlDisplayName)"
            [value]="control.value ?? ''"
            class="input value-field-text w-100"
        />
        <ul *ngIf="isInvalid(control)" class="d-flex flex-column m-0 p-0 validation-error">
            <li *ngFor="let error of validationErrors(controlDisplayName, control.errors)">
                <small class="text-danger w-100">{{ error }}</small>
            </li>
        </ul>
    </div>
</ng-template>
