<div class="header-wrapper">
    <div class="header">
        <div class="header-back">
            <div (click)="openNav()" class="side-nav-button"><mat-icon>menu</mat-icon></div>
            <div class="search-bar">
                <mat-icon class="search-bar-icon" svgIcon="search-icon"></mat-icon>
                <input type="text" class="search-bar-input" placeholder="Search..." />
            </div>
        </div>
        <div class="header-right">
            <mat-icon class="header-icon">notifications_none</mat-icon>
            <div class="cursor-pointer user-avatar" [matMenuTriggerFor]="menu">
                <img src="./assets/user-avatar.png" />
            </div>
            <mat-menu #menu="matMenu" class="topheadermenu">
                <div class="nym-dropdown">
                    <div class="nym-dropdown-content">
                        <div class="nym-dropdown-item" (click)="viewAdminProfile()">Profile</div>
                        <mat-divider class="nym-dropdown-divider"></mat-divider>
                        <div class="nym-dropdown-item" (click)="openChangePassword()">Change password</div>
                        <div class="nym-dropdown-item" (click)="logout()">Log out</div>
                    </div>
                </div>
            </mat-menu>
        </div>
    </div>
</div>
