import { NgModule } from '@angular/core';
import { ValueFieldDirective, ValueFieldOptionDirective } from '@lib/modules/value-field/directives';
import {
    ValueFieldComponent,
    ValueFieldInputComponent,
    ValueFieldMultiSelectComponent,
    ValueFieldRadioComponent,
    ValueFieldRangeComponent,
    ValueFieldSelectComponent,
    ValueFieldStepperComponent,
    ValueFieldSwitchComponent,
    ValueFieldTextareaComponent,
    ValueFieldOTPComponent,
} from '@lib/modules/value-field/components';
import { AngularMaterialModule } from '../../../material.module';
import { OptionalPlaceholderPipe } from '@lib/pipes';
import { ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from '@lib/components/spinner/spinner.component';

const ValueFieldDirectives = [ValueFieldDirective, ValueFieldOptionDirective];
const ValueFieldComponents = [
    ValueFieldComponent,
    ValueFieldInputComponent,
    ValueFieldMultiSelectComponent,
    ValueFieldRadioComponent,
    ValueFieldSelectComponent,
    ValueFieldStepperComponent,
    ValueFieldSwitchComponent,
    ValueFieldTextareaComponent,
    ValueFieldRangeComponent,
    ValueFieldOTPComponent,
];

@NgModule({
    imports: [AngularMaterialModule, OptionalPlaceholderPipe, ReactiveFormsModule, SpinnerComponent],
    declarations: [...ValueFieldDirectives, ...ValueFieldComponents],
    exports: [...ValueFieldDirectives, ...ValueFieldComponents],
})
export class ValueFieldModule {}
