import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../../../material.module';
import { MenuItem } from '@lib/interfaces/menu-items.interface';
import { SettingsService } from '@lib/services/settings/settings.service';
import { environment } from '@env/environment';
import { Permission } from '@lib/enums/permissions.enum';
@Component({
    standalone: true,
    imports: [CommonModule, RouterModule, AngularMaterialModule],
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent {
    imgUrl = '';
    version = environment.version;
    constructor(private router: Router, public settingService: SettingsService) {
        this.settingService.settingsDetails.subscribe({
            next: (item: any) => {
                this.imgUrl = item.logo_file_name;
            },
        });
    }
    panelOpenState = false;
    menuItems: MenuItem[] = [
        {
            title: 'Home',
            icon: 'home',
            link: '/home',
            permissions: [Permission.Home],
        },
        {
            title: 'Analytics',
            icon: 'report',
            link: '/report',
            permissions: [],
        },
        {
            title: 'Customers',
            icon: 'customer',
            link: '/customers',
            permissions: [Permission.Customer],
        },
        {
            title: 'Card Product',
            icon: 'product',
            link: '/card-product',
            permissions: [Permission.ProductManagement, Permission.CardProducts],
        },
        {
            title: 'Access Management',
            icon: 'access-management',
            link: '/access-management',
            permissions: [Permission.AccessManagement],
        },
        {
            title: 'Transactions',
            icon: 'transaction',
            link: '/transactions',
            permissions: [Permission.CustomerCards, Permission.CustomerCardsTransactions],
        },
        {
            title: 'Cards',
            icon: 'card-icon-sidemenu',
            link: '/cards',
            permissions: [Permission.Customer, Permission.CustomerCards],
        },
        {
            title: 'Product Settings',
            icon: 'settings',
            link: '/product-settings',
            permissions: [Permission.ProductSettings],
        },
    ];

    lowerMenuItems: MenuItem[] = [
        {
            section: 'PERSONAL',
            title: 'Feedback',
            icon: 'feedback',
            permissions: [],
            link: '/feedback',
        },
        {
            title: 'Settings',
            icon: 'settings',
            permissions: [Permission.Settings],
            link: '/control-center',
            children: [
                {
                    title: 'Logo Settings',
                    link: '/control-center/settings',
                    permissions: [Permission.Logo],
                },
            ],
        },
        {
            section: 'MANUALS',
            title: 'API Catalog',
            icon: 'api-catalog',
            permissions: [],
            external: true,
            link: 'https://nymcard.com/docs/api',
        },
        {
            title: 'Getting Started Guide',
            icon: 'guide',
            permissions: [],
            external: true,
            link: 'https://docs.nymcard.com/',
        },
    ];

    checkExpandedItem(item: string): boolean {
        return this.router.url.includes(item);
    }
}
