import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularMaterialModule } from '../../material.module';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { SideNavComponent } from '@lib/components/side-nav/side-nav.component';
import { HeaderComponent } from '@lib/components/header/header.component';
import { ScrollService } from '@lib/services/scroll.service';
import { SettingsService } from '@lib/services/settings/settings.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatDrawer } from '@angular/material/sidenav';
import { BaseComponent } from '@lib/components';

@Component({
    standalone: true,
    imports: [RouterModule, AngularMaterialModule, SideNavComponent, HeaderComponent],
    selector: 'app-home',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('matDrawer') protected matDrawer: MatDrawer;
    @ViewChild('matDrawerContent', { read: ElementRef }) protected matDrawerContent: ElementRef<HTMLElement>;

    protected mobileQuery: MediaQueryList;
    protected isWelcomeScreen = false;

    private mobileQueryListener: () => void;
    private mutationObserver: MutationObserver;
    private scrollTriggerThreshold = 50; // Pixels

    public constructor(private readonly changeDetectorRef: ChangeDetectorRef, private readonly scrollService: ScrollService, private readonly settingService: SettingsService, private readonly media: MediaMatcher, private readonly router: Router) {
        super();

        this.setWelcomeScreen();
    }

    public ngOnInit(): void {
        this.getTenantSettings();

        this.observeMediaChanges();
    }

    public ngAfterViewInit(): void {
        this.observeMatDrawerContentChildrenHeight();
    }

    public override ngOnDestroy(): void {
        super.ngOnDestroy();

        this.mobileQuery.removeEventListener('change', this.mobileQueryListener);

        if (this.mutationObserver) this.mutationObserver.disconnect();
    }

    protected matDrawerContentScroll(event: Event): void {
        const matDrawerContent: HTMLElement = event.target as HTMLElement;

        const targetScrollBottom: number = matDrawerContent.scrollTop + matDrawerContent.clientHeight + this.scrollTriggerThreshold;

        if (targetScrollBottom >= matDrawerContent.scrollHeight) this.scrollService.bottomReached();
    }

    @HostListener('window:resize')
    protected observeWindowInnerHeightChanges(): void {
        const matDrawerContent: HTMLElement = this.matDrawerContent.nativeElement;

        const targetScrollBottom: number = matDrawerContent.scrollTop + matDrawerContent.clientHeight + this.scrollTriggerThreshold;

        if (targetScrollBottom >= matDrawerContent.scrollHeight) this.scrollService.bottomReached();
    }

    protected observeMatDrawerContentChildrenHeight(): void {
        const matDrawerContent: HTMLElement = this.matDrawerContent.nativeElement;

        this.mutationObserver = new MutationObserver((): void => {
            const targetScrollBottom: number = matDrawerContent.scrollTop + matDrawerContent.clientHeight + this.scrollTriggerThreshold;

            if (targetScrollBottom < matDrawerContent.scrollHeight) this.scrollService.bottomLost();

            if (targetScrollBottom >= matDrawerContent.scrollHeight) this.scrollService.bottomReached();
        });

        this.mutationObserver.observe(matDrawerContent, { subtree: true, childList: true });
    }

    private getTenantSettings(): void {
        this.settingService.getTenantSetting().subscribe({
            next: ({ metadata }): void => {
                this.settingService.settingsDetails.emit(metadata[0]);
            },
        });
    }

    private observeMediaChanges(): void {
        this.mobileQuery = this.media.matchMedia('(max-width: 1320px)');
        this.mobileQueryListener = () => this.changeDetectorRef.detectChanges();
        this.mobileQuery.addEventListener('change', this.mobileQueryListener);
    }

    private setWelcomeScreen(): void {
        this.addSubscription(
            this.router.events.subscribe((event): void => {
                if (event instanceof NavigationEnd) {
                    this.isWelcomeScreen = event.url === '/';
                }
            }),
        );
    }
}
