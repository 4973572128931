<div class="sidebar">
    <div class="sidebar-content">
        <div class="d-flex align-items-center sidebar-header-logo">
            <img class="sidebar-logo" [src]="imgUrl" />
            <span class="sidebar-version">{{ version }}</span>
        </div>
        <mat-list>
            <ng-container *ngFor="let item of menuItems">
                <span *ngIf="item.section" class="sidebar-sub-menu">{{ item.section }}</span>
                <div *appAccessControlHideElementDirective="item.permissions">
                    <mat-list-item *ngIf="!item.children" class="sidebar-item" [routerLink]="item.link" routerLinkActive="active">
                        <div class="sidebar-item-content">
                            <div *ngIf="!item.external" class="d-flex justify-content-between align-items-center">
                                <mat-icon class="side-bar-icon" [ngClass]="item.icon" [svgIcon]="item.icon"></mat-icon>
                                <span>{{ item.title }}</span>
                            </div>
                            <a *ngIf="item.external" [href]="item.link" target="_blank">
                                <mat-icon class="side-bar-icon" [ngClass]="item.icon" [svgIcon]="item.icon"></mat-icon>
                                <span>{{ item.title }}</span>
                            </a>
                        </div>
                    </mat-list-item>
                </div>
            </ng-container>
        </mat-list>

        <mat-list class="mt-auto">
            <ng-container *ngFor="let item of lowerMenuItems">
                <span *ngIf="item.section" class="sidebar-sub-menu">{{ item.section }}</span>
                <div *appAccessControlHideElementDirective="item.permissions">
                    <mat-list-item *ngIf="!item.children" class="sidebar-item" [routerLink]="item.link" routerLinkActive="active">
                        <div class="sidebar-item-content">
                            <div *ngIf="!item.external" class="d-flex justify-content-between align-items-center">
                                <mat-icon class="side-bar-icon" [ngClass]="item.icon" [svgIcon]="item.icon"></mat-icon>
                                <span>{{ item.title }}</span>
                            </div>
                            <a *ngIf="item.external" [href]="item.link" target="_blank">
                                <mat-icon class="side-bar-icon" [ngClass]="item.icon" [svgIcon]="item.icon"></mat-icon>
                                <span>{{ item.title }}</span>
                            </a>
                        </div>
                    </mat-list-item>
                    <mat-expansion-panel
                        *ngIf="item.children"
                        class="sidebar-item-expand"
                        [class.mat-elevation-z0]="true"
                        [class.active-expand]="checkExpandedItem('control-center')"
                        hideToggle
                    >
                        <mat-expansion-panel-header class="sidebar-item-expand-header">
                            <mat-icon class="side-bar-icon" svgIcon="settings"></mat-icon>
                            <span class="sidebar-item-expand-title">{{ item.title }}</span>
                        </mat-expansion-panel-header>
                        <mat-nav-list>
                            <div *ngFor="let childItem of item.children">
                                <mat-list-item
                                    *appAccessControlHideElementDirective="childItem.permissions"
                                    class="sidebar-item-expand-sub"
                                    [routerLink]="childItem.link"
                                    routerLinkActive="active-sub"
                                >
                                    <span class="sidebar-item-expand-text">{{ childItem.title }}</span>
                                </mat-list-item>
                            </div>
                        </mat-nav-list>
                    </mat-expansion-panel>
                </div>
            </ng-container>
        </mat-list>
    </div>
</div>
