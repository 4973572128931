import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAuthInterface, IAuthResponse, ILoginResponse } from '@lib/interfaces/auth.interface';
import { environment } from '../../../../environments/environment';
import { DeepPartial } from '@lib/interfaces';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private environment_url: string;

    constructor(private http: HttpClient, private httpHandler: HttpBackend) {
        this.environment_url = environment.api_url;
    }

    loginWithCredentials(credentials: DeepPartial<IAuthInterface>): Observable<IAuthResponse> {
        return this.http.post<IAuthResponse>(`${this.environment_url}nfront/v1/admins/login`, credentials);
    }

    continueAuthWithMobileVerification(id: string, code: string): Observable<IAuthResponse> {
        const url = `${this.environment_url}nfront/v1/admins/login/${id}:continueWithMobileVerification?code=${code}`;
        return this.http.put<IAuthResponse>(url, '');
    }

    signinWithCustomToken(loginResponse: IAuthResponse): Observable<ILoginResponse> {
        this.setLocalStorage(loginResponse);
        const params = new HttpParams({ fromObject: { custom_token: loginResponse.custom_token.token } });
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'x-no-authorization': 'true',
        });
        const url = `${this.environment_url}nfront/v1/oauth2/token:signinWithCustomToken`;
        return this.http.post<ILoginResponse>(url, params, { headers });
    }

    changePassword(adminId: string | null, body: { old_password: string; new_password: string }): Observable<any> {
        const url = `${this.environment_url}nfront/v1/admins/login/${adminId}:continueWithPasswordChange`;
        const headers = new HttpHeaders({
            'X-Consumer-Username': localStorage.getItem('tenantKey') || '',
            apikey: localStorage.getItem('tenantKey') || '',
        });
        return this.http.put<any>(url, body, { headers });
    }

    changePasswordV2(adminId: string | null, token: string, body: { old_password: string; new_password: string }): Observable<any> {
        const url = `${this.environment_url}nfront/v1/admins/sessions/password/forgot:changePassword?id=${adminId}&token=${token}`;
        const headers = new HttpHeaders({
            'X-Consumer-Username': localStorage.getItem('tenantKey') || '',
            apikey: localStorage.getItem('tenantKey') || '',
        });
        return this.http.post<any>(url, { password: body.new_password }, { headers });
    }

    forgotPassword(email: string): Observable<any> {
        const url = `${this.environment_url}nfront/v1/admins/sessions/password/forgot/${email}`;
        const headers = new HttpHeaders({
            'X-Consumer-Username': localStorage.getItem('tenantKey') || '',
            apikey: localStorage.getItem('tenantKey') || '',
        });
        return this.http.post<any>(url, {}, { headers });
    }



    private setLocalStorage(loginResponse: IAuthResponse): void {
        localStorage.setItem('adminId', loginResponse.id);

        if (loginResponse.custom_token) {
            localStorage.setItem('customToken', loginResponse.custom_token.token);
            localStorage.setItem('permissions', loginResponse.custom_token.scopes);
        }

        if (loginResponse.admin) {
            localStorage.setItem('id', loginResponse.admin.id);
        }
    }
}
