<mat-drawer-container class="app-container">
    <mat-drawer #matDrawer *ngIf="!isWelcomeScreen" [mode]="mobileQuery.matches ? 'over' : 'side'" [opened]="!mobileQuery.matches" class="app-side-nav">
        <app-side-nav />
    </mat-drawer>
    <mat-drawer-content #matDrawerContent (scroll)="matDrawerContentScroll($event)" class="drawer-content">
        <app-header (open)="matDrawer.toggle()" *ngIf="!isWelcomeScreen" />
        <div [ngClass]="{ 'main-body': !isWelcomeScreen }">
            <router-outlet></router-outlet>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
